import { getApi } from "@/store/api/api";
import { Action } from "@/store/actionType";
import { FeedBack } from "../models/feedback";

export default new (class FeedBackAPI {
  public async getAllPaginatedFeedBack(query = ""): Promise<any> {
    const action = Action.getPaginatedFeedBack + query;

    const response = await getApi(action);
    return response as FeedBack[];
  }
})();
