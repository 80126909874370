
















































































import { Vue, Component } from "vue-property-decorator";
import { AdminRouter } from "@/utils/routePathContsant";
import feedBackStore from "@/store/modules/feedback";
import helpers from "@/utils/helpers";

@Component({
  components: {},
})
export default class FeedBackClass extends Vue {
  feedBackFilter: {
    feedBackType?: string;
    page: number;
  } = { page: 1 };

  async created() {
    await feedBackStore.getAllFeedBack(this.feedBackFilter);
  }
  get AdminRouter() {
    return AdminRouter;
  }

  get feedBackList() {
    return feedBackStore.paginatedFeedBackList;
  }

  toMomentDate(date: string) {
    return helpers.toMomentFormat(date);
  }

  async handlePageChange(page: number) {
    this.feedBackFilter.page = page;
    await feedBackStore.getAllFeedBack(this.feedBackFilter);
  }
  async onHandleChange() {
    await feedBackStore.getAllFeedBack(this.feedBackFilter);
  }
}
