import store from "@/store";
import {
  Module,
  VuexModule,
  MutationAction,
  getModule,
} from "vuex-module-decorators";
import api from "@/store/api/feedBack";
import { PaginatedFeedBack } from "../models/feedback";

@Module({ name: "FeedBackModule", dynamic: true, store })
class FeedBackModule extends VuexModule {
  public paginatedFeedBackList: PaginatedFeedBack = {} as PaginatedFeedBack;

  @MutationAction
  async getAllFeedBack(params: any) {
    let query = "?";
    if (params.feedBackType) {
      query += `&feedback_type=${params.feedBackType}`;
    }
    if (params.page) {
      query += `&page=${params.page}`;
    }
    const response = await api.getAllPaginatedFeedBack(query);

    return { paginatedFeedBackList: response as PaginatedFeedBack };
  }
}

export default getModule(FeedBackModule);
